/* Utils *****************************************/

import { LinkToType } from "../navigationProperties";
import type { MultiStepDef } from "legacy/constants/ActionConstants";

// Makes an interface T keep its props or be a string. Omits K
type WithOptionalStringProperties<T, K extends keyof T> = {
  [P in keyof T]: P extends K ? T[P] : T[P] | string;
};

/* Base ******************************************/
export enum MenuItemType {
  Link = "LINK",
  Button = "BUTTON",
  Divider = "DIVIDER",
}

type BaseActionableMenuItem = {
  label: string;
  icon?: string; // maps to a Superblocks icon key
  iconPosition?: "LEFT" | "RIGHT";
  isVisible: boolean;
  isDisabled: boolean;
};
type BaseLinkMenuItem = BaseActionableMenuItem & {
  type: MenuItemType.Link;
  targetBlank?: boolean; // opens in same tab/page if undefined
};

type LinkToPageMenuItem = BaseLinkMenuItem & {
  linkTo: LinkToType.Page;
  routeId?: string;
  keepQueryParams?: boolean;
  routePathDescriptor?: string;
  routeParams?: Record<string, string>;
  queryParams?: Record<string, string>;
};

type LinkToCustomURL = BaseLinkMenuItem & {
  linkTo: LinkToType.CustomUrl;
  href: string;
};

type LinkToApp = BaseLinkMenuItem & {
  linkTo: LinkToType.App;
  queryParams?: Record<string, string>;
  keepQueryParams?: boolean;
  targetApp: {
    id: string;
    name: string;
    url: string;
  };
};

export type LinkMenuItem = LinkToPageMenuItem | LinkToCustomURL | LinkToApp;

export type ButtonMenuItem = BaseActionableMenuItem & {
  type: MenuItemType.Button;
  openOn?: "hover" | "click";
  onClick?: MultiStepDef;
};

type Divider = {
  type: MenuItemType.Divider;
  isVisible: boolean;
};

/* Component *************************************/
type WithChildren = { children?: ComponentMenuItem[] };
export type ComponentLinkMenuItem = BaseLinkMenuItem & {
  href: string;
  hasMissingRouteParams?: boolean;
  urlForInternalNavigation?: string;
} & WithChildren;
export type ComponentButtonMenuItem = ButtonMenuItem & WithChildren;
type ComponentDivider = Divider;
export type ComponentMenuItem = (
  | ComponentLinkMenuItem
  | ComponentButtonMenuItem
  | ComponentDivider
) & { isVisible: boolean };

/* DSL *******************************************/
type MappedMenuItemsContainer = {
  mapping: WithOptionalStringProperties<
    LinkMenuItem | ButtonMenuItem | DynamicTypeMapping,
    "type"
  >;
  data: string;
};

type DynamicTypeMapping = Omit<LinkMenuItem, "type"> &
  Omit<ButtonMenuItem, "type"> & {
    type: "DYNAMIC";
    dynamicType: string;
  };

export enum MenuChildrenType {
  Mapped = "mapped",
  Manual = "manual",
}

export type ActionableManualMenuItem = (LinkMenuItem | ButtonMenuItem) &
  ManualMenuChildren;
export type ManualMenuItem = ActionableManualMenuItem | Divider;

type ManualMenuChildren = {
  childrenType: MenuChildrenType;
  mappedChildren?: MappedMenuItemsContainer;
  manualChildren?: ManualMenuItem[];
};
