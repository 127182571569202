import { isArray } from "lodash";
import shallowEqual from "shallowequal";
import { WidgetTypes, type Hierarchy } from "legacy/constants/WidgetConstants";
import { pickStaticProps, type WidgetLayoutProps } from "legacy/widgets/shared";
import type { DynamicWidgetsVisibilityState } from "../visibilitySelectors";
import type { DynamicWidgetsLayoutState } from "legacy/reducers/evaluationReducers/dynamicLayoutReducer";
import type { WidgetMap, WidgetProps } from "legacy/widgets";

/**
 * Attempts to salvage the target object by applying the static props to it. This is done to avoid
 * unnecessary re-renders. If the widget has not changed, we can just return the target object. If
 * the widget has changed, we need to return a new object.
 *
 * @param {WidgetMap} sWidgets - WidgetMap - This is the map of all current widgets
 * @param {WidgetMap} cachedSWidgets - The widgets that were previously rendered
 * @param {DynamicWidgetsVisibilityState} eWidgets - Evaluated/Meta props for widgets
 * @param {DynamicWidgetsVisibilityState} cachedEWidgets - Evaluated/Meta props for widgets that were previously rendered
 * @param {DynamicWidgetsLayoutState} dWidgets - Px height info for fill parent widgets
 * @param {DynamicWidgetsLayoutState} cachedDWidgets - Px height info for fill parent widgets that were previously rendered
 * @param {Hierarchy | WidgetLayoutProps} target - The widget structure that we are applying the props to.
 * @param {boolean} isTargetNew - This is a boolean that indicates whether the target is a new object
 * or not. If it is a new object, we can mutate it as it's memory location has already changed.
 * If it is not a new object, we need to return a new object to indicate the change when rendering.
 * @param {Record<string, any>} aiEdits - Map of currently previewed widget edits, should override static props.
 * @param {Record<string, any>} cachedAiEdits - Map of currently previewed widget edits, should override static props.
 * @returns the target param with static props applied
 */
export function applyStaticProps({
  sWidgets,
  cachedSWidgets,
  eWidgets,
  cachedEWidgets,
  dWidgets,
  cachedDWidgets,
  aiEdits,
  cachedAiEdits,
  target,
  isTargetNew,
}: {
  sWidgets: WidgetMap;
  cachedSWidgets: WidgetMap;
  eWidgets: DynamicWidgetsVisibilityState;
  cachedEWidgets: DynamicWidgetsVisibilityState;
  dWidgets: DynamicWidgetsLayoutState;
  cachedDWidgets: DynamicWidgetsLayoutState;
  aiEdits?: Record<string, Partial<Omit<WidgetProps, "children">>>;
  cachedAiEdits?: Record<string, Partial<Omit<WidgetProps, "children">>>;
  target: Hierarchy | WidgetLayoutProps;
  isTargetNew: boolean;
}): WidgetLayoutProps {
  const applyPropsToTarget = (
    target: Hierarchy | WidgetLayoutProps,
    forceInvalidation?: boolean,
  ): WidgetLayoutProps => {
    const targetWidget = sWidgets[target.widgetId];
    const parentWidget = sWidgets[targetWidget?.parentId];
    const aiEdit = aiEdits?.[target.widgetId];
    let changed = sWidgets[target.widgetId] !== cachedSWidgets[target.widgetId];

    const targetEvaluatedProps = eWidgets[target.widgetId];
    if (targetEvaluatedProps !== cachedEWidgets[target.widgetId]) {
      changed = true;
    }

    const targetLayoutProps = dWidgets[target.widgetId];
    if (targetLayoutProps !== cachedDWidgets[target.widgetId]) {
      changed = true;
    }

    const cachedAiEdit = cachedAiEdits?.[target.widgetId];
    if (cachedAiEdit !== aiEdit) {
      changed = true;
    }

    // If the parent is a canvas and it changed, consider this widget changed too as the parent
    // has layout properties that affect the children's display
    if (
      parentWidget &&
      (parentWidget.type === WidgetTypes.CANVAS_WIDGET ||
        parentWidget.type === WidgetTypes.SECTION_WIDGET) &&
      sWidgets[parentWidget.widgetId] !== cachedSWidgets[parentWidget.widgetId]
    ) {
      changed = true;
    }

    // If the widget is a modal or slideout,
    // we need to get all new children because the widget effects position of children in the tree
    const targetChangesEffectAllChildren =
      target.type === WidgetTypes.MODAL_WIDGET ||
      target.type === WidgetTypes.SLIDEOUT_WIDGET;

    if (!targetWidget) {
      return target as WidgetLayoutProps;
    }

    const { children, ...widgetProps } = targetWidget;
    const dynamicWidgetLayout = {
      dynamicWidgetLayout: targetLayoutProps,
    };

    const newChildren = isArray(target.children)
      ? ([...target.children] as WidgetLayoutProps[])
      : undefined;
    if (target.children && newChildren && target.children) {
      target.children.forEach((child, i) => {
        if (child !== undefined) {
          newChildren[i] = applyPropsToTarget(
            child,
            forceInvalidation || targetChangesEffectAllChildren,
          );
        }
      });
    }
    if (changed || forceInvalidation) {
      // If the widget has changed, we need to return a new object
      return {
        ...pickStaticProps(widgetProps, true),
        ...targetEvaluatedProps,
        ...dynamicWidgetLayout,
        ...(aiEdit ? pickStaticProps(aiEdit, true) : {}),
        children: newChildren,
      };
    } else if (
      (newChildren?.length ?? 0) > 0 &&
      !shallowEqual(newChildren, target.children)
    ) {
      // If the widget has not changed but a child has, we need to return a new object
      // This allows the parent to re-render because of the way we handle children via props
      return {
        ...pickStaticProps(widgetProps, true),
        ...targetEvaluatedProps,
        ...dynamicWidgetLayout,
        ...(aiEdit ? pickStaticProps(aiEdit, true) : {}),
        children: newChildren,
      };
    } else {
      // If the widget has not changed, we can just return the target object
      // This avoids a re-render of the leaf widget
      if (isTargetNew) {
        Object.assign(target, pickStaticProps(widgetProps, true));
        Object.assign(target, targetEvaluatedProps);
        Object.assign(target, dynamicWidgetLayout);
        Object.assign(target, aiEdit ? pickStaticProps(aiEdit, true) : {});
      }
      return target as WidgetLayoutProps;
    }
  };

  return applyPropsToTarget(target);
}
